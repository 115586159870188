import GradeSelectorEnglish from 'components/arc-core/GradeSelectorEnglish'
import GradeSelectorSpanish from 'components/arc-core/GradeSelectorSpanish'
import TransformYourClassroom from 'components/arc-core/TransformYourClassroom'
import LargeForm from 'components/forms/LargeForm'
import SectionTitle from 'components/global/SectionTitle'
import { Button, LanguageToggle } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import OverlayVideo from 'components/overlay-video'
import { Link, PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import queryString from 'query-string'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'

export default function ARCCorePage({ location }: PageProps) {
	const [language, setLanguage] = useState<'english' | 'spanish'>('english')

	const params = queryString.parse(location.search)

	const lang = params?.lang

	useEffect(() => {
		if (lang === 'sp') setLanguage('spanish')
		else setLanguage('english')
	}, [lang])

	return (
		<Layout pageName="ARC Core">
			<Section>
				<div className="absolute top-0 left-0 z-0 h-full w-full bg-cover bg-no-repeat opacity-90" style={{ backgroundImage: 'url(/images/arc-core/books-bg.jpg)' }}>
					<div className="white-transparent-gradient-strong h-full w-full"></div>
				</div>
				<Row center className="relative z-auto pt-10 sm:pt-20">
					<Col width="w-full sm:w-2/3">
						<div>
							<StaticImage data-cy="hero-logo" src="../../static/images/logos/arc-core-logo-halo.png" loading="eager" alt="arc core logo" />
						</div>
					</Col>
				</Row>
				<Row center className="relative z-auto -mt-4 md:-mt-12">
					<Col width="w-full" className="text-center">
						<H1 data-cy="hero-heading" className="mx-auto mb-3 max-w-3xl">
							One Comprehensive Solution for Transformative Literacy, Grades K&#8211;12
						</H1>
						<P data-cy="hero-copy" className="mx-auto max-w-3xl">
							Grounded in the science of reading, ARC Core meets the English and Spanish literacy needs of all students in any learning environment. Through extensive
							reading, writing, research, and analysis, students develop agency and expertise in a wide variety of disciplines, preparing them to lead in a changing
							world. <Link to="/state-reviews">Read expert reviews.</Link>
						</P>
					</Col>
					<Col className="mt-10">
						<LanguageToggle language={language} onClickEnglish={() => setLanguage('english')} onClickSpanish={() => setLanguage('spanish')} />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<TransformYourClassroom language={language} />
			</Section>
			<Section margin="mt-20 sm:mt-32">
				<SectionTitle
					title="What You Get"
					text={[
						<span key="sectionTitlePTag1">
							Choose your grade below to view what is included in each unit across the year. You can navigate unit by unit using the arrows or simply scroll to view
							all materials. Materials shown are recommended theme selections for each grade. <Link to="/themes">Explore all available themes and genres.</Link>
							<br></br>
							<span className="text-xs">All title and basket images are representative samples; actual titles may vary.</span>
						</span>
					]}
				/>

				<LazyLoad>
					<Row className="mt-10" overflow={false}>
						{language === 'english' && <GradeSelectorEnglish />}
						{language === 'spanish' && <GradeSelectorSpanish />}
					</Row>
				</LazyLoad>
			</Section>
			<Section>
				<SectionTitle
					title="Looking for More Themes?"
					text="With many more themes to choose from in English and Spanish, you can be sure you'll find something your students will want to learn."
				/>
				<Row center>
					<Link to="/themes">
						<Button label="Explore All Themes" size="lg" />
					</Link>
				</Row>
			</Section>
			<Section id="watchItInAction" margin="mt-20 sm:mt-40">
				<SectionTitle
					title="Watch It in Action"
					text="Learn more about how ARC Core’s gradual-release model creates independent, engaged learners whose Final Projects showcase both content knowledge and ELA skills."
				/>
				<Row className="items-center">
					<Col width="w-full md:w-5/12" className="">
						<div className="-mr-0 md:-mr-48">
							<OverlayVideo title="Bloodsuckers" />
						</div>
					</Col>
					<Col width="w-full md:w-7/12" className="bg-white py-0 pl-4 sm:bg-slate-50 md:py-40 md:pl-56">
						<span className="mt-3 mb-1 inline-block text-3xl font-semibold">Engaged Student Authors</span>
						<P size="md" className="mb-3">
							Watch Tyrique read <i>The Bloodsuckers</i>, a book he authored and illustrated as a Research Labs Final Project.
						</P>
						<Link to="/student-books">
							<Button label="More Student Books" />
						</Link>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="ARC Core" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
