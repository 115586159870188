import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Modal from 'components/global/Modal'
import { Col, Row } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import MaterialButton from './MaterialButton'

const WritersNotebook = ({ image, grade }) => {
	const [show, setShow] = React.useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const data = useStaticQuery(graphql`
		query {
			contentfulModal(name: { eq: "Notebooks 2-5 (Spanish)" }) {
				title
				description {
					description
				}
				image {
					id
					title
					gatsbyImageData(placeholder: BLURRED)
				}
			}
		}
	`)

	const title = data.contentfulModal.title
	const description = data.contentfulModal.description.description
	let pages = []
	if (grade === 2) pages = data.contentfulModal.image.slice(0, 2)
	else if (grade === 3) pages = data.contentfulModal.image.slice(2, 4)
	else if (grade === 4) pages = data.contentfulModal.image.slice(4, 5)
	else pages = data.contentfulModal.image.slice(5, 6)

	return (
		<React.Fragment>
			<MaterialButton onClick={handleShow} image={image} title={title} />
			<Modal title={title} isOpen={show} handleClose={handleClose} wide>
				<Row>
					<Col>
						<p className="text-lg text-slate-400">{description}</p>
					</Col>
					<Col>
						<Carousel showThumbs={false} dynamicHeight showStatus={false} infiniteLoop>
							{pages.map((page) => {
								return (
									<div key={page.id}>
										<GatsbyImage image={page.gatsbyImageData} alt={page.title} className="block w-full" />
									</div>
								)
							})}
						</Carousel>
					</Col>
				</Row>
			</Modal>
		</React.Fragment>
	)
}

export default WritersNotebook
