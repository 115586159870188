import { Theme, ThemeMapQuery } from 'common-types'
import UnitBar from 'components/arc-core/UnitBar'
import { graphql, useStaticQuery } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React, { useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Grade01 from './Grades/Grade01'
import Grade02 from './Grades/Grade02'
import Grade03 from './Grades/Grade03'
import Grade04 from './Grades/Grade04'
import Grade05 from './Grades/Grade05'
import Grade06 from './Grades/Grade06'
import Grade07 from './Grades/Grade07'
import Grade08 from './Grades/Grade08'
import Grade09 from './Grades/Grade09'
import Grade10 from './Grades/Grade10'
import Grade11 from './Grades/Grade11'
import Grade12 from './Grades/Grade12'
import GradeK from './Grades/GradeK'

const GradeSelectorEnglish = () => {
	const windowSize = () => window.innerWidth
	const [tabIndex, setTabIndex] = useState(1)
	const [grade, setGrade] = useState('1')
	const [unit, setUnit] = useState('1')
	const [unitDetect, setUnitDetect] = useState('0')
	const [show, setShow] = useState(false)
	const [showUnit, setShowUnit] = useState(false)
	const data = useStaticQuery<ThemeMapQuery>(query).contentfulThemeMap

	const language = 'english'

	const detectUnit = (unit: string) => {
		setUnitDetect(unit)
	}

	return (
		<>
			<UnitBar grade={grade} unitDetect={unitDetect} language={language} />
			<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} selectedTabClassName="bg-gray-200 font-bold" className="mt-6 w-full">
				{windowSize() >= 1024 && (
					<TabList className="sticky top-0 hidden flex-wrap border-b bg-white lg:flex" style={{ zIndex: 99 }}>
						<Tab
							onClick={() => setGrade('K')}
							className="flex flex-grow cursor-pointer items-center justify-center rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200"
						>
							Grade
							<br />K
						</Tab>
						<Tab onClick={() => setGrade('1')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />1
						</Tab>
						<Tab onClick={() => setGrade('2')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />2
						</Tab>
						<Tab onClick={() => setGrade('3')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />3
						</Tab>
						<Tab onClick={() => setGrade('4')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />4
						</Tab>
						<Tab onClick={() => setGrade('5')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />5
						</Tab>

						<Tab onClick={() => setGrade('6')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />6
						</Tab>
						<Tab onClick={() => setGrade('7')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />7
						</Tab>
						<Tab onClick={() => setGrade('8')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />8
						</Tab>
						<Tab onClick={() => setGrade('9')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />9
						</Tab>
						<Tab onClick={() => setGrade('10')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />
							10
						</Tab>
						<Tab onClick={() => setGrade('11')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />
							11
						</Tab>
						<Tab onClick={() => setGrade('12')} className="flex-grow cursor-pointer rounded-t border-t border-l border-r bg-white p-3 text-center hover:bg-gray-200">
							Grade
							<br />
							12
						</Tab>
					</TabList>
				)}

				<div className="sticky top-0 flex justify-center bg-white shadow" style={{ zIndex: 99 }}>
					<div className="relative m-4 inline-block text-left lg:hidden">
						<div>
							<span className="rounded shadow-sm">
								<button
									onClick={() => {
										setShow(!show)
									}}
									type="button"
									className="focus:ring-blue inline-flex w-full justify-center rounded bg-ab-100 px-4 py-2 text-sm font-semibold leading-5 text-white transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
									id="options-menu"
									aria-haspopup="true"
									aria-expanded="true"
								>
									{`Grade ${grade}`}
									<svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
										<path
											fillRule="evenodd"
											d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</button>
							</span>
						</div>
						<div
							className={`left-0 z-20 mt-2 w-40 origin-top-right rounded shadow-lg ${
								show ? 'absolute scale-100 transform opacity-100' : 'hidden scale-95 transform opacity-0'
							} transition duration-100 ease-out`}
						>
							<div className="rounded bg-white ring-1 ring-black ring-opacity-5">
								<div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
									{windowSize() <= 1023 && (
										<TabList>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('K')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade K
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('1')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 1
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('2')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 2
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('3')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 3
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('4')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 4
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('5')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 5
											</Tab>

											<Tab
												onClick={() => {
													setShow(false)
													setGrade('6')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 6
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('7')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 7
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('8')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 8
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('9')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 9
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('10')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 10
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('11')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 11
											</Tab>
											<Tab
												onClick={() => {
													setShow(false)
													setGrade('12')
												}}
												className="cursor-pointer px-2 py-1 hover:bg-gray-200"
											>
												Grade 12
											</Tab>
										</TabList>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="relative m-4 inline-block text-left lg:hidden">
						<div>
							<span className="rounded shadow-sm">
								<button
									onClick={() => {
										setShowUnit(!showUnit)
									}}
									type="button"
									className="focus:ring-blue inline-flex w-full justify-center rounded bg-ab-100 px-4 py-2 text-sm font-semibold leading-5 text-white transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
									id="options-menu"
									aria-haspopup="true"
									aria-expanded="true"
								>
									{`Unit ${unit}`}
									<svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
										<path
											fillRule="evenodd"
											d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</button>
							</span>
						</div>
						<div
							className={`left-0 z-20 mt-2 w-40 origin-top-right rounded shadow-lg ${
								showUnit ? 'absolute scale-100 transform opacity-100' : 'hidden scale-95 transform opacity-0'
							} transition duration-100 ease-out`}
						>
							<div className="relative rounded bg-white ring-1 ring-black ring-opacity-5">
								<div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
									<button
										onClick={() => {
											setShowUnit(false)
											setUnit('1')
											scrollTo(`#grade-${grade}-unit-1`)
										}}
										className="block w-full px-4 py-2 text-left text-sm font-light leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 hover:no-underline focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
										role="menuitem"
									>
										Unit 1
									</button>
									<button
										onClick={() => {
											setShowUnit(false)
											setUnit('2')
											scrollTo(`#grade-${grade}-unit-2`)
										}}
										className="block w-full px-4 py-2 text-left text-sm font-light leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 hover:no-underline focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
										role="menuitem"
									>
										Unit 2
									</button>
									<button
										onClick={() => {
											setShowUnit(false)
											setUnit('3')
											scrollTo(`#grade-${grade}-unit-3`)
										}}
										className="block w-full px-4 py-2 text-left text-sm font-light leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 hover:no-underline focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
										role="menuitem"
									>
										Unit 3
									</button>
									<button
										onClick={() => {
											setShowUnit(false)
											setUnit('4')
											scrollTo(`#grade-${grade}-unit-4`)
										}}
										className="block w-full px-4 py-2 text-left text-sm font-light leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 hover:no-underline focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
										role="menuitem"
									>
										Unit 4
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<TabPanel>
					<GradeK litLab={data.gradeK[0]} themes={data.gradeK.slice(1) as Theme[]} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade01 litLab={data.grade1[0]} themes={data.grade1.slice(1) as Theme[]} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade02 data={data.grade2} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade03 data={data.grade3} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade04 data={data.grade4} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade05 data={data.grade5} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade06 data={data.grade6} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade07 data={data.grade7} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade08 data={data.grade8} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade09 data={data.grade9} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade10 data={data.grade10} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade11 data={data.grade11} language={language} detectUnit={detectUnit} />
				</TabPanel>
				<TabPanel>
					<Grade12 data={data.grade12} language={language} detectUnit={detectUnit} />
				</TabPanel>
			</Tabs>
		</>
	)
}

const query = graphql`
	query {
		contentfulThemeMap(name: { eq: "Default (2021)" }) {
			name
			language
			standard
			gradeK {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade1 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade2 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade3 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade4 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade5 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade6 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade7 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade8 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade9 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade10 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade11 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
			grade12 {
				... on ContentfulTheme {
					...arcCoreThemeMapTheme
				}
				... on ContentfulLiteracyLab {
					...arcCoreThemeMapLitLab
				}
			}
		}
	}
`

export default GradeSelectorEnglish
