import { Row } from 'components/grid'
import React from 'react'

import Unit from './Unit'

interface Props {
	grade: string
	unitDetect: string
	language: 'english' | 'spanish'
}

const UnitBar = ({ grade, unitDetect, language }: Props) => {
	return (
		<div className="relative z-50 hidden w-full bg-white px-4 lg:sticky lg:block" style={{ top: `${language === 'english' ? '-45px' : '-65px'}` }}>
			<Row center className="mb-3">
				<Unit
					unit="1"
					title={language === 'english' ? 'ARC Literacy Lab' : 'Laboratorio de lectoescritura'}
					text="Building a Community of Avid Readers & Writers"
					className="pl-4 pr-4 md:pr-0"
					grade={grade}
					unitDetect={unitDetect}
					language={language}
				/>
				<Unit
					unit="2"
					title={language === 'english' ? 'Informational Reading' : 'Lectura informativa'}
					text={language === 'english' ? 'Writing & Research in One Science Topic' : 'Lectura informativa, escritura e investigación en un tema de Ciencias'}
					className="pr-4 pl-4 md:pl-0 lg:pr-0"
					grade={grade}
					unitDetect={unitDetect}
					language={language}
				/>
				<Unit
					unit="3"
					title={language === 'english' ? 'Literature Reading' : 'Lectura de literatura'}
					text={language === 'english' ? 'Writing & Analysis in One Literary Genre' : 'Lectura de literatura, escritura y análisis en un género literario'}
					className="pl-4 pr-4 md:pr-0 lg:pl-0"
					grade={grade}
					unitDetect={unitDetect}
					language={language}
				/>
				<Unit
					unit="4"
					title={language === 'english' ? 'Argument Writing' : 'Escritura de argumentos'}
					text={
						language === 'english'
							? 'Writing & Research in One Science/Social Studies Topic'
							: 'Escritura de argumentos e investigación en un tema de Ciencias/Estudios Sociales'
					}
					className="pr-4 pl-4 md:pl-0"
					grade={grade}
					unitDetect={unitDetect}
					language={language}
				/>
			</Row>
		</div>
	)
}

export default UnitBar
