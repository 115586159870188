import { Col, Container, Row } from 'components/grid'
import React from 'react'

import FinalProjectOrganizer from '../FinalProjectOrganizer'
import GraphicOrganizer from '../GraphicOrganizer'
import HookBooks from '../HookBooks'
import JeffreyWilhelmText from '../JeffreyWilhelmText'
import LeveledGenreLibrary from '../LeveledGenreLibrary'
import LeveledLibrary from '../LeveledLibrary'
import MaterialItem from '../MaterialItem'
import PairedCoreTexts from '../PairedCoreTexts'
import Unit from '../Unit'
import WholeYear from '../WholeYear'
import WritingCards from '../WritingCards'

const GradeEight = ({ data, detectUnit, language }) => {
	return (
		<Container fluid>
			<Unit
				language={language}
				detectUnit={detectUnit}
				id="grade-8-unit-1"
				color="bg-slate-800"
				unit="1"
				title={language === 'english' ? 'ARC Literacy Lab' : 'Laboratorio de lectoescritura'}
				text="Students develop the academic routines of successful readers and writers with a content-rich literacy block that builds on their interests and abilities."
			>
				<Row center>
					{data[0].frameworkCover && (
						<Col width="sm:w-1/2" className="material-column">
							<MaterialItem image={data[0].frameworkCover.gatsbyImageData} name="Literacy Lab" />
						</Col>
					)}

					{data[0].fictionHookBook && data[0].relatedInformationalText ? (
						<Col width="sm:w-1/2" className="material-column">
							<PairedCoreTexts hookBook={data[0].fictionHookBook.gatsbyImageData} infoText={data[0].relatedInformationalText.gatsbyImageData} />
						</Col>
					) : null}

					{data[0].hookBookLibrary && (
						<Col width="sm:w-1/2 md:w-full" className="material-column">
							<HookBooks baskets={data[0].hookBookLibrary} />
						</Col>
					)}
				</Row>
				{data[0].hundredBookChallenge && data[0].fstk ? (
					<WholeYear data={data[0]} language={language} hundredBookChallenge={data[0].hundredBookChallenge} grade="6-8" fstk={data[0].fstk.gatsbyImageData} />
				) : null}
			</Unit>
			<Unit
				language={language}
				id="grade-8-unit-2"
				wilhelmCheck={false}
				color="bg-ab-100"
				unit="2"
				title={language === 'english' ? 'Informational Research Lab' : 'Laboratorio de investigación: Escritura informativa'}
				detectUnit={detectUnit}
				text="Students study a Science topic while becoming experts in reading and writing informational text."
				data={data[1]}
			>
				<Row center>
					{data[1].frameworkCover && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[1].frameworkCover.gatsbyImageData} name="Teacher Guide" />
						</Col>
					)}

					{data[1].finalProjectOrganizer && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<FinalProjectOrganizer images={data[1].finalProjectOrganizer} />
						</Col>
					)}

					{data[1].researchCard && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[1].researchCard.gatsbyImageData} name="Research Card" />
						</Col>
					)}

					{data[1].coreText && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[1].coreText.gatsbyImageData} name="Core Text" />
						</Col>
					)}

					{/* <Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<JeffreyWilhelmText unit={2} />
					</Col> */}
					<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<WritingCards unit={2} language={language} />
					</Col>
					{data[1].anchorTitles && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<MaterialItem image={data[1].anchorTitles.gatsbyImageData} name="Anchor Titles" />
						</Col>
					)}

					{data[1].leveledLibrary && (
						<Col className="material-column">
							<LeveledLibrary baskets={data[1].leveledLibrary} />
						</Col>
					)}
				</Row>
			</Unit>
			<Unit
				language={language}
				id="grade-8-unit-3"
				color="bg-core-g"
				unit="3"
				title={language === 'english' ? 'Literature Genre Lab' : 'Laboratorio de género literario'}
				detectUnit={detectUnit}
				text="Students study literary elements in a genre while constructing narratives in that same genre."
				data={data[2]}
			>
				<Row center>
					{data[2].frameworkCover && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[2].frameworkCover.gatsbyImageData} name="Teacher Guide" />
						</Col>
					)}

					{data[2].graphicOrganizer && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<GraphicOrganizer images={data[2].graphicOrganizer} />
						</Col>
					)}

					{data[2].researchCard && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[2].researchCard.gatsbyImageData} name="Genre Card" />
						</Col>
					)}

					{data[2].coreText && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[2].coreText.gatsbyImageData} name="Core Text" />
						</Col>
					)}

					<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<JeffreyWilhelmText unit={3} />
					</Col>
					{data[2].informationalTextSet && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<MaterialItem image={data[2].informationalTextSet.gatsbyImageData} name="Informational Text Set" />
						</Col>
					)}

					{data[2].anchorTitles && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<MaterialItem image={data[2].anchorTitles.gatsbyImageData} name="Anchor Titles" />
						</Col>
					)}

					{data[2].leveledLibrary && (
						<Col className="material-column">
							<LeveledGenreLibrary baskets={data[2].leveledLibrary} />
						</Col>
					)}
				</Row>
			</Unit>
			<Unit
				language={language}
				id="grade-8-unit-4"
				color="bg-ar-100"
				unit="4"
				title={language === 'english' ? 'Argument Research Lab' : 'Laboratorio de investigación: Argumento'}
				detectUnit={detectUnit}
				text="Students study a Science/Social Studies topic while constructing arguments using their own research."
				data={data[3]}
			>
				<Row center>
					{data[3].frameworkCover && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[3].frameworkCover.gatsbyImageData} name="Teacher Guide" />
						</Col>
					)}

					{data[3].finalProjectOrganizer && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<FinalProjectOrganizer images={data[3].finalProjectOrganizer} />
						</Col>
					)}

					{data[3].researchCard && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[3].researchCard.gatsbyImageData} name="Research Card" />
						</Col>
					)}

					{data[3].coreText && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={data[3].coreText.gatsbyImageData} name="Core Text" />
						</Col>
					)}

					<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<JeffreyWilhelmText unit={4} />
					</Col>
					<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<WritingCards unit={4} language={language} />
					</Col>
					{data[3].anchorTitles && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<MaterialItem image={data[3].anchorTitles.gatsbyImageData} name="Anchor Titles" />
						</Col>
					)}

					{data[3].leveledLibrary && (
						<Col className="material-column">
							<LeveledLibrary baskets={data[3].leveledLibrary} />
						</Col>
					)}
				</Row>
			</Unit>
		</Container>
	)
}

export default GradeEight
