import { ArcCoreGrade } from 'common-types'
import WritersNotebook from 'components/arc-core/WritersNotebookK1'
import { Col, Container, Row } from 'components/grid'
import React from 'react'

import FinalProjectOrganizer from '../FinalProjectOrganizer'
import GraphicOrganizer from '../GraphicOrganizer'
import JeffreyWilhelmText from '../JeffreyWilhelmText'
import LeveledGenreLibrary from '../LeveledGenreLibrary'
import LeveledLibrary from '../LeveledLibrary'
import MaterialItem from '../MaterialItem'
import Notebook from '../Notebook'
import ReadAloudCollectionFour from '../ReadAloudCollectionFour'
import ReadAloudCollectionOne from '../ReadAloudCollectionOne'
import Unit from '../Unit'
import WholeYear from '../WholeYear'
import WritingCards from '../WritingCards'

const GradeOne = ({ litLab, themes, detectUnit, language }: ArcCoreGrade) => {
	return (
		<Container>
			<Unit
				detectUnit={detectUnit}
				language={language}
				id="grade-1-unit-1"
				color="bg-slate-800"
				unit="1"
				title={language === 'english' ? 'ARC Literacy Lab' : 'Laboratorio de lectoescritura'}
				text="Students develop the academic routines of successful readers and writers with a content-rich literacy block that builds on their interests and abilities."
			>
				<Row center>
					{litLab.frameworkCover && (
						<Col width="sm:w-1/3" className="material-column">
							<MaterialItem image={litLab.frameworkCover.gatsbyImageData} name="Literacy Lab" />
						</Col>
					)}

					{language === 'english' && (
						<Col width="sm:w-1/3" className="material-column">
							<MaterialItem name="Equipped for Reading Success" />
						</Col>
					)}
					{litLab.notebooks && (
						<Col width="sm:w-1/3" className="material-column">
							<WritersNotebook grade={1} image={litLab.notebooks[0].gatsbyImageData} />
						</Col>
					)}
					{litLab.readAloudCollection && (
						<Col className="material-column">
							<ReadAloudCollectionFour baskets={litLab.readAloudCollection} />
						</Col>
					)}
				</Row>
				{litLab.hundredBookChallenge && litLab.fstk ? (
					<WholeYear data={litLab} language={language} hundredBookChallenge={litLab.hundredBookChallenge} grade="k-2" fstk={litLab.fstk.gatsbyImageData} />
				) : null}
			</Unit>
			<Unit
				language={language}
				id="grade-1-unit-2"
				wilhelmCheck={false}
				color="bg-ab-100"
				unit="2"
				title={language === 'english' ? 'Informational Research Lab' : 'Laboratorio de investigación: Escritura informativa'}
				detectUnit={detectUnit}
				text="Students study a Science topic while becoming experts in reading and writing informational text."
				data={themes[0]}
				gradeKToOne={true}
			>
				<Row center>
					{themes[0].frameworkCover && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={themes[0].frameworkCover.gatsbyImageData} name="Teacher Guide" />
						</Col>
					)}
					{themes[0].finalProjectOrganizer && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<FinalProjectOrganizer images={themes[0].finalProjectOrganizer} />
						</Col>
					)}
					{themes[0].researchCard && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={themes[0].researchCard.gatsbyImageData} name="Research Card" />
						</Col>
					)}
					<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
						<WritingCards unit={2} language={language} />
					</Col>
					{themes[0].notebook && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<Notebook image={themes[0].notebook.gatsbyImageData} grade="1" />
						</Col>
					)}
					{themes[0].readAloudCollection && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<ReadAloudCollectionOne baskets={themes[0].readAloudCollection} />
						</Col>
					)}
					{/* <Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<JeffreyWilhelmText unit={2} />
					</Col> */}
					{themes[0].leveledLibrary && (
						<Col className="material-column">
							<LeveledLibrary baskets={themes[0].leveledLibrary} />
						</Col>
					)}
				</Row>
			</Unit>
			<Unit
				language={language}
				id="grade-1-unit-3"
				color="bg-core-g"
				unit="3"
				title={language === 'english' ? 'Literature Genre Lab' : 'Laboratorio de género literario'}
				detectUnit={detectUnit}
				text="Students study literary elements in a genre while constructing narratives in that same genre."
				data={themes[1]}
				gradeKToOne={true}
			>
				<Row center>
					{themes[1].frameworkCover && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={themes[1].frameworkCover.gatsbyImageData} name="Teacher Guide" />
						</Col>
					)}
					{themes[1].graphicOrganizer && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<GraphicOrganizer images={themes[1].graphicOrganizer} />
						</Col>
					)}
					{themes[1].researchCard && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={themes[1].researchCard.gatsbyImageData} name="Genre Card" />
						</Col>
					)}
					{themes[1].readAloudCollection && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<ReadAloudCollectionOne baskets={themes[1].readAloudCollection} />
						</Col>
					)}
					{themes[1].notebook && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<Notebook image={themes[1].notebook.gatsbyImageData} grade="1" />
						</Col>
					)}
					{themes[1].informationalTextSet && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<MaterialItem image={themes[1].informationalTextSet.gatsbyImageData} name="Informational Text Set" />
						</Col>
					)}
					<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<JeffreyWilhelmText unit={3} />
					</Col>
					{themes[1].leveledLibrary && (
						<Col className="material-column">
							<LeveledGenreLibrary baskets={themes[1].leveledLibrary} />
						</Col>
					)}
				</Row>
			</Unit>
			<Unit
				language={language}
				id="grade-1-unit-4"
				color="bg-ar-100"
				unit="4"
				title={language === 'english' ? 'Argument Research Lab' : 'Laboratorio de investigación: Argumento'}
				detectUnit={detectUnit}
				text="Students study a Science/Social Studies topic while constructing arguments using their own research."
				data={themes[2]}
				gradeKToOne={true}
			>
				<Row center>
					{themes[2].frameworkCover && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={themes[2].frameworkCover.gatsbyImageData} name="Teacher Guide" />
						</Col>
					)}
					{themes[2].finalProjectOrganizer && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<FinalProjectOrganizer images={themes[2].finalProjectOrganizer} />
						</Col>
					)}
					{themes[2].researchCard && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<MaterialItem image={themes[2].researchCard.gatsbyImageData} name="Research Card" />
						</Col>
					)}
					<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
						<WritingCards unit={4} language={language} />
					</Col>
					{themes[2].notebook && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<Notebook image={themes[2].notebook.gatsbyImageData} grade="1" />
						</Col>
					)}
					<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<ReadAloudCollectionOne baskets={themes[2].readAloudCollection} />
					</Col>
					<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<JeffreyWilhelmText unit={4} />
					</Col>
					<Col className="material-column">
						<LeveledLibrary baskets={themes[2].leveledLibrary} />
					</Col>
				</Row>
			</Unit>
		</Container>
	)
}

export default GradeOne
