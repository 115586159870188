import { ArcCoreGrade } from 'common-types'
import { Col, Container, Row } from 'components/grid'
import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import { Waypoint } from 'react-waypoint'

import PrintWrapper from '../../themes/ThemeChart/PrintWrapper'
import BigBooksLitLab from '../BigBooksLitLab'
import LeveledLibrary75 from '../LeveledLibrary75'
import MaterialItem from '../MaterialItem'
import ModulePrintCard from '../ModulePrintCard'
import Notebook from '../Notebook'
import ReadAloudCollectionFour from '../ReadAloudCollectionFour'
import ReadAloudCollectionTwo from '../ReadAloudCollectionTwo'
import Unit from '../Unit'
import WholeYear from '../WholeYear'
import WritersNotebook from '../WritersNotebookK1'

const GradeK = ({ litLab, themes, detectUnit, language }: ArcCoreGrade) => {
	// Setting the state for the collapsable units
	const [unit2Open, setUnit2Open] = useState(true)
	const [unit2Button, setUnit2Button] = useState(true)
	const [unit3Open, setUnit3Open] = useState(true)
	const [unit3Button, setUnit3Button] = useState(true)
	const [unit4Open, setUnit4Open] = useState(true)
	const [unit4Button, setUnit4Button] = useState(true)
	return (
		<Container>
			<Unit
				detectUnit={detectUnit}
				id="grade-K-unit-1"
				color="bg-slate-800"
				unit="1"
				title={language === 'english' ? 'ARC Literacy Lab' : 'Laboratorio de lectoescritura'}
				text="Students develop the academic routines of successful readers and writers with a content-rich literacy block that builds on their interests and abilities."
				language={language}
			>
				<Row center>
					{litLab.frameworkCover && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<MaterialItem image={litLab.frameworkCover.gatsbyImageData} name="Literacy Lab" />
						</Col>
					)}

					{language === 'english' && (
						<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
							<MaterialItem name="Phonemic Awareness" />
						</Col>
					)}
					<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
						<BigBooksLitLab language={language} />
					</Col>
					{litLab.notebooks && (
						<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
							<WritersNotebook grade={0} image={litLab.notebooks[0].gatsbyImageData} />
						</Col>
					)}

					{litLab.readAloudCollection && (
						<Col width="sm:w-1/2 lg:w-3/4" className="material-column">
							<ReadAloudCollectionFour baskets={litLab.readAloudCollection} />
						</Col>
					)}
				</Row>
				{litLab.hundredBookChallenge && litLab.fstk ? (
					<WholeYear cape data={litLab} language={language} hundredBookChallenge={litLab.hundredBookChallenge} grade="k-2" fstk={litLab.fstk.gatsbyImageData} />
				) : null}
			</Unit>
			{/* @ts-ignore */}
			<Waypoint onEnter={() => detectUnit('2')} bottomOffset={500}>
				<div>
					<Row className="unit-heading flex-column bg-ab-100 pt-3">
						<Col className="text-center text-white">
							<span className="block text-2xl font-semibold tracking-wide">{language === 'english' ? 'Unit 2: Zoology' : 'Unidad 2: Zoología'}</span>
							<button
								className="unit-toggle-button focus:outline-none"
								onClick={() => {
									setUnit2Open(!unit2Open)
									setUnit2Button(!unit2Button)
								}}
								value="toggle grade k unit 2"
								aria-controls="grade-k-unit-2"
								aria-label="toggle grade k unit 2"
								aria-expanded={unit2Open}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className={`h-8 w-8 ${unit2Button ? 'rotate-0' : 'rotate-180'}`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2.5}
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
								</svg>
							</button>
						</Col>
					</Row>
				</div>
			</Waypoint>
			<Collapse
				isOpened={unit2Open}
				theme={{
					collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
					content: 'ReactCollapse--content'
				}}
			>
				<div id="grade-K-unit-2" style={{ scrollMarginTop: 240 }}>
					<Row center>
						{themes[0].frameworkCover && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<MaterialItem image={themes[0].frameworkCover.gatsbyImageData} name="Teacher Guide" />
							</Col>
						)}
						{themes[0].readAloudCollection && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<ReadAloudCollectionTwo baskets={themes[0].readAloudCollection} />
							</Col>
						)}
						{themes[0].scienceTools && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<MaterialItem image={themes[0].scienceTools.gatsbyImageData} name="Science Tools" />
							</Col>
						)}
						{themes[0].bigBooks && (
							<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
								<MaterialItem image={themes[0].bigBooks.gatsbyImageData} name="Big Books" />
							</Col>
						)}
						{themes[0].notebook && (
							<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
								<Notebook image={themes[0].notebook.gatsbyImageData} grade="K" />
							</Col>
						)}
						{themes[0].leveledLibrary && (
							<Col width="lg:w-1/2" className="material-column">
								<LeveledLibrary75 baskets={themes[0].leveledLibrary} />
							</Col>
						)}
					</Row>
					<div className="flex justify-end pr-6 pb-6 text-ab-100">
						<PrintWrapper buttonText="Module Checklist">
							<div className="hidden print:block">
								<ModulePrintCard data={themes[0]} gradeKToOne={true} wilhelmCheck={false} />
							</div>
						</PrintWrapper>
					</div>
				</div>
			</Collapse>
			{/* @ts-ignore */}
			<Waypoint onEnter={() => detectUnit('3')} bottomOffset={500}>
				<div>
					<Row className="unit-heading flex-column bg-core-g pt-3">
						<Col className="text-center text-white">
							<span className="block text-2xl font-semibold tracking-wide">{language === 'english' ? 'Unit 3: Ecology' : 'Unidad 3: Ecología'}</span>
							<button
								className="unit-toggle-button focus:outline-none"
								onClick={() => {
									setUnit3Open(!unit3Open)
									setUnit3Button(!unit3Button)
								}}
								value="toggle grade k unit 3"
								aria-controls="grade-k-unit-3"
								aria-label="toggle grade k unit 3"
								aria-expanded={unit3Open}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className={`h-8 w-8 ${unit3Button ? 'rotate-0' : 'rotate-180'}`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2.5}
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
								</svg>
							</button>
						</Col>
					</Row>
				</div>
			</Waypoint>
			<Collapse
				isOpened={unit3Open}
				theme={{
					collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
					content: 'ReactCollapse--content'
				}}
			>
				<div id="grade-K-unit-3" style={{ scrollMarginTop: 240 }}>
					<Row center>
						{themes[1].frameworkCover && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<MaterialItem image={themes[1].frameworkCover.gatsbyImageData} name="Teacher Guide" />
							</Col>
						)}

						{themes[1].readAloudCollection && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<ReadAloudCollectionTwo baskets={themes[1].readAloudCollection} />
							</Col>
						)}

						{themes[1].scienceTools && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<MaterialItem image={themes[1].scienceTools.gatsbyImageData} name="Science Tools" />
							</Col>
						)}
					</Row>
					{language === 'english' ? (
						<>
							<Row center>
								{themes[1].bigBooks && (
									<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
										<MaterialItem image={themes[1].bigBooks.gatsbyImageData} name="Big Books" />
									</Col>
								)}
								{themes[1].notebook && (
									<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
										<Notebook image={themes[1].notebook.gatsbyImageData} grade="K" />
									</Col>
								)}
							</Row>
							<Row>
								{themes[1].leveledLibrary && (
									<Col className="material-column">
										<LeveledLibrary75 baskets={themes[1].leveledLibrary} />
									</Col>
								)}
							</Row>{' '}
						</>
					) : (
						<>
							<Row center>
								{themes[1].bigBooks && (
									<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
										<MaterialItem image={themes[1].bigBooks.gatsbyImageData} name="Big Books" />
									</Col>
								)}
								{themes[1].notebook && (
									<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
										<MaterialItem image={themes[1].notebook.gatsbyImageData} name="Notebook" />
									</Col>
								)}
								{themes[1].leveledLibrary && (
									<Col width="sm:w-1/2 lg:w-1/2" className="material-column">
										<LeveledLibrary75 baskets={themes[1].leveledLibrary} />
									</Col>
								)}
							</Row>
						</>
					)}
				</div>
				<div className="flex justify-end pr-6 pb-6 text-ab-100">
					<PrintWrapper buttonText="Module Checklist">
						<div className="hidden print:block">
							<ModulePrintCard data={themes[1]} gradeKToOne={true} wilhelmCheck={false} />
						</div>
					</PrintWrapper>
				</div>
			</Collapse>
			{/* @ts-ignore */}
			<Waypoint onEnter={() => detectUnit('4')} bottomOffset={500}>
				<div>
					<Row className="unit-heading flex-column bg-ar-100 pt-3">
						<Col className="text-center text-white">
							<span className="block text-2xl font-semibold tracking-wide">{language === 'english' ? 'Unit 4: Entomology' : 'Unidad 4: Entomología'}</span>
							<button
								className="unit-toggle-button focus:outline-none"
								onClick={() => {
									setUnit4Open(!unit4Open)
									setUnit4Button(!unit4Button)
								}}
								value="toggle grade k unit 4"
								aria-controls="grade-k-unit-4"
								aria-label="toggle grade k unit 4"
								aria-expanded={unit4Open}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className={`h-8 w-8 ${unit4Button ? 'rotate-0' : 'rotate-180'}`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2.5}
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
								</svg>
							</button>
						</Col>
					</Row>
				</div>
			</Waypoint>
			<Collapse
				isOpened={unit4Open}
				theme={{
					collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
					content: 'ReactCollapse--content'
				}}
			>
				<div id="grade-K-unit-4" style={{ scrollMarginTop: 240 }}>
					<Row center>
						{themes[2].frameworkCover && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<MaterialItem image={themes[2].frameworkCover.gatsbyImageData} name="Teacher Guide" />
							</Col>
						)}
						{themes[2].readAloudCollection && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<ReadAloudCollectionTwo baskets={themes[2].readAloudCollection} />
							</Col>
						)}
						{themes[2].scienceTools && (
							<Col width="sm:w-1/2 lg:w-1/3" className="material-column">
								<MaterialItem image={themes[2].scienceTools.gatsbyImageData} name="Science Tools" />
							</Col>
						)}
						{themes[2].bigBooks && (
							<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
								<MaterialItem image={themes[2].bigBooks.gatsbyImageData} name="Big Books" />
							</Col>
						)}
						{themes[2].notebook && (
							<Col width="sm:w-1/2 lg:w-1/4" className="material-column">
								<Notebook image={themes[2].notebook.gatsbyImageData} grade="K" />
							</Col>
						)}
						{themes[2].leveledLibrary && (
							<Col width="lg:w-1/2" className="material-column">
								<LeveledLibrary75 baskets={themes[2].leveledLibrary} />
							</Col>
						)}
					</Row>
				</div>
				<div className="flex justify-end pr-6 pb-6 text-ab-100">
					<PrintWrapper buttonText="Module Checklist">
						<div className="hidden print:block">
							<ModulePrintCard data={themes[2]} gradeKToOne={true} wilhelmCheck={false} />
						</div>
					</PrintWrapper>
				</div>
			</Collapse>
		</Container>
	)
}

export default GradeK
