import { Theme } from 'common-types'
import React from 'react'
import ModuleListItem from '../themes/template-components/ModuleListItem'

interface Props {
	data: Theme
	gradeKToOne?: boolean
	wilhelmCheck?: boolean
}
// Had to make this a class component to be able to use the react-to-print package
class ModulePrintCard extends React.Component<Props> {
	render() {
		const { data, gradeKToOne = false, wilhelmCheck = true } = this.props
		return (
			<div>
				<div className="flex flex-col bg-white">
					{data.unit === '2' || data.slug === 'zoology' ? <span className="my-3 text-center text-3xl font-medium">Informational Module</span> : null}
					{data.unit === '3' || data.slug === 'ecology' ? <span className="my-3 text-center text-3xl font-medium">Genre Module</span> : null}
					{data.unit === '4' || data.slug === 'entomology' ? <span className="my-3 text-center text-3xl font-medium">Argument Module</span> : null}
					<div className="mb-4 text-center text-lg text-slate-400">
						<span>This module includes the following:</span>
					</div>
					<ul className="list-checkmark mx-auto my-8">
						<ModuleListItem text="Teacher Guide" />

						{data.scienceTools && <ModuleListItem text="Science Tools" />}
						{data.bigBooks && <ModuleListItem text="Big Books" />}
						{data.graphicOrganizer && <ModuleListItem text="Graphic Organizers" />}
						{data.finalProjectOrganizer && <ModuleListItem text="Final Project Organizers" />}
						{(data.researchCard && data.unit === '2') || (data.researchCard && data.unit === '4') ? <ModuleListItem text="Research Card" /> : null}
						{data.researchCard && data.unit === '3' && gradeKToOne !== true ? <ModuleListItem text="Genre Card" /> : null}
						{data.unit === '2' || data.unit === '4' ? <ModuleListItem text="Writing Cards" /> : null}
						{data.coreText && <ModuleListItem text="Core Text(s)" />}
						{data.exemplarPack && <ModuleListItem text="Exemplar Pack" />}
						{data.notebook && gradeKToOne && <ModuleListItem text="Word Study & Lab Notebooks" />}
						{data.notebook && !gradeKToOne && <ModuleListItem text="Lab Notebook" />}
						{data.readAloudCollection && <ModuleListItem text="Read-Aloud Collection" />}
						{data.anchorTitles && <ModuleListItem text="Anchor Titles" />}
						{data.informationalTextSet && <ModuleListItem text="Informational Text Set" />}
						{wilhelmCheck && <ModuleListItem text="Text by Jeffrey Wilhelm" />}
						{data.unit === '2' || data.unit === '4' ? (
							<ModuleListItem text="Research Library" />
						) : (
							data.unit === '3' && <ModuleListItem text="Literary Genre Library" />
						)}
						{/* Check for kindergarten */}
						{gradeKToOne && wilhelmCheck !== true && data.name !== 'Wild & Endangered Animals' ? <ModuleListItem text="Research Library" /> : null}
					</ul>
				</div>
			</div>
		)
	}
}

export default ModulePrintCard
