import { Col } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React from 'react'
import { Collapse } from 'react-collapse'

interface Props {
	unit: '1' | '2' | '3' | '4'
	title: string
	text: string
	className: string
	grade: string
	unitDetect: string
	language: 'english' | 'spanish'
}

const Unit = ({ unit, title, text, className, grade, unitDetect, language }: Props) => {
	function determineColor() {
		if (unit === '1') return { text: 'text-slate-800', background: 'bg-slate-800' }
		if (unit === '2') return { text: 'text-ab-100', background: 'bg-ab-100' }
		if (unit === '3') return { text: 'text-core-g', background: 'bg-core-g' }
		if (unit === '4') return { text: 'text-ar-100', background: 'bg-ar-100' }
		else return { text: 'text-ar-100', background: 'bg-ar-100' }
	}

	return (
		<Col width="w-full md:w-1/2 lg:w-1/4" gutter="none" className={`mt-4 md:mt-0 ${className ? className : ''}`}>
			<div className="flex h-full flex-col text-center">
				<Collapse
					isOpened={grade !== 'K'}
					theme={{
						collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
						content: 'ReactCollapse--content'
					}}
				>
					<span className="text-2xl font-semibold">
						{language === 'english' ? 'Unit' : 'Unidad'} {unit}
					</span>
					<div>
						<span className={`text-xl ${determineColor().text}`}>{title}</span>
						<P size="md" shade="darker" className="mx-auto mb-2 w-4/5">
							{text}
						</P>
					</div>
				</Collapse>
				<Collapse
					isOpened={grade === 'K'}
					theme={{
						collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
						content: 'ReactCollapse--content'
					}}
				>
					<div className={`${language === 'english' ? 'h-32' : 'h-36'}`}></div>
				</Collapse>
				<button
					onClick={() => scrollTo(`#grade-${grade}-unit-${unit}`)}
					className={`${determineColor().background} ${unitDetect === unit ? 'opacity-100' : 'opacity-50'} mt-auto w-full text-white`}
					style={{ clipPath: 'polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%)', height: 35 }}
				>
					{`${language === 'english' ? 'Grade' : 'Grado'} ${grade} ${language === 'english' ? 'Unit' : 'Unidad'} ${unit}`}
				</button>
			</div>
		</Col>
	)
}

export default Unit
