import { Theme } from 'common-types'
import { Col, Row } from 'components/grid'
import React, { ReactNode, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Waypoint } from 'react-waypoint'

import PrintWrapper from '../themes/ThemeChart/PrintWrapper'
import ModulePrintCard from './ModulePrintCard'

interface Props {
	color: string
	unit: string
	title: string
	text: string
	id: string
	detectUnit: (unit: string) => void
	language: 'english' | 'spanish'
	data?: Theme
	gradeKToOne?: boolean
	wilhelmCheck?: boolean
	children: ReactNode
}

const Unit = ({ color, unit, title, text, id, detectUnit, language, data, gradeKToOne, wilhelmCheck, children }: Props) => {
	const [unitOpen, setUnitOpen] = useState(true)

	return (
		<React.Fragment>
			{/* @ts-ignore */}
			<Waypoint onEnter={() => detectUnit(unit)} bottomOffset={500}>
				<div>
					<Row className={`${color} items-center`}>
						<Col className="flex flex-col items-center pt-3 text-center text-white">
							<span className="text-2xl font-semibold tracking-wide">
								{language === 'english' ? 'Unit' : 'Unidad'} {unit}: {title}
							</span>
							<p className="mb-0 text-lg tracking-wide text-white">{text}</p>
							<button
								onClick={() => setUnitOpen(!unitOpen)}
								className="focus:outline-none"
								value="toggle collapse"
								aria-label="toggle collapse"
								aria-expanded={unitOpen}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className={`h-8 w-8 ${unitOpen ? 'rotate-0' : 'rotate-180'}`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2.5}
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
								</svg>
							</button>
						</Col>
					</Row>
				</div>
			</Waypoint>
			<Collapse
				isOpened={unitOpen}
				theme={{
					collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
					content: 'ReactCollapse--content'
				}}
			>
				<div id={id} style={{ scrollMarginTop: 250 }}>
					{children}
				</div>
				{data && (
					<div className="flex justify-end pr-6 pb-6 text-ab-100">
						<PrintWrapper buttonText="Module Checklist">
							<div className="hidden print:block">
								<ModulePrintCard data={data} gradeKToOne={gradeKToOne} wilhelmCheck={wilhelmCheck} />
							</div>
						</PrintWrapper>
					</div>
				)}
			</Collapse>
		</React.Fragment>
	)
}

export default Unit
